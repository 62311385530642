$(document).ready(function () {
  var current_fs, next_fs, previous_fs; //fieldsets
  var opacity;
  var currentStep = 0;
  var anzSteps = $('#registerform fieldset').length;

  // * zu allen required Feldern hinzufügen
  // $('label.required').append('&nbsp;<strong>*</strong>&nbsp;');

  // eigene Synchrone Methode, da async bei remot Anfragen zu Problemen führt.
  // https://stackoverflow.com/questions/7247250/jquery-validation-not-waiting-for-remote-validation-to-return-true-considers-fo
  $.validator.addMethod(
    "letterswithbasicpunc",
    function (value, element) {
      return this.optional(element) || /\D+/i.test(value);
    },
    "Nur Buchstaben erlaubt."
  );

  $.validator.addMethod(
    "iban",
    function (value, element) {
      // Some quick simple tests to prevent needless work
      if (this.optional(element)) {
        return true;
      }

      // Remove spaces and to upper case
      var iban = value.replace(/ /g, "").toUpperCase(),
        ibancheckdigits = "",
        leadingZeroes = true,
        cRest = "",
        cOperator = "",
        countrycode,
        ibancheck,
        charAt,
        cChar,
        bbanpattern,
        bbancountrypatterns,
        ibanregexp,
        i,
        p;

      // Check for IBAN code length.
      // It contains:
      // country code ISO 3166-1 - two letters,
      // two check digits,
      // Basic Bank Account Number (BBAN) - up to 30 chars
      var minimalIBANlength = 5;
      if (iban.length < minimalIBANlength) {
        return false;
      }

      // Check the country code and find the country specific format
      countrycode = iban.substring(0, 2);
      bbancountrypatterns = {
        AL: "\\d{8}[\\dA-Z]{16}",
        AD: "\\d{8}[\\dA-Z]{12}",
        AT: "\\d{16}",
        AZ: "[\\dA-Z]{4}\\d{20}",
        BE: "\\d{12}",
        BH: "[A-Z]{4}[\\dA-Z]{14}",
        BA: "\\d{16}",
        BR: "\\d{23}[A-Z][\\dA-Z]",
        BG: "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
        CR: "\\d{17}",
        HR: "\\d{17}",
        CY: "\\d{8}[\\dA-Z]{16}",
        CZ: "\\d{20}",
        DK: "\\d{14}",
        DO: "[A-Z]{4}\\d{20}",
        EE: "\\d{16}",
        FO: "\\d{14}",
        FI: "\\d{14}",
        FR: "\\d{10}[\\dA-Z]{11}\\d{2}",
        GE: "[\\dA-Z]{2}\\d{16}",
        DE: "\\d{18}",
        GI: "[A-Z]{4}[\\dA-Z]{15}",
        GR: "\\d{7}[\\dA-Z]{16}",
        GL: "\\d{14}",
        GT: "[\\dA-Z]{4}[\\dA-Z]{20}",
        HU: "\\d{24}",
        IS: "\\d{22}",
        IE: "[\\dA-Z]{4}\\d{14}",
        IL: "\\d{19}",
        IT: "[A-Z]\\d{10}[\\dA-Z]{12}",
        KZ: "\\d{3}[\\dA-Z]{13}",
        KW: "[A-Z]{4}[\\dA-Z]{22}",
        LV: "[A-Z]{4}[\\dA-Z]{13}",
        LB: "\\d{4}[\\dA-Z]{20}",
        LI: "\\d{5}[\\dA-Z]{12}",
        LT: "\\d{16}",
        LU: "\\d{3}[\\dA-Z]{13}",
        MK: "\\d{3}[\\dA-Z]{10}\\d{2}",
        MT: "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
        MR: "\\d{23}",
        MU: "[A-Z]{4}\\d{19}[A-Z]{3}",
        MC: "\\d{10}[\\dA-Z]{11}\\d{2}",
        MD: "[\\dA-Z]{2}\\d{18}",
        ME: "\\d{18}",
        NL: "[A-Z]{4}\\d{10}",
        NO: "\\d{11}",
        PK: "[\\dA-Z]{4}\\d{16}",
        PS: "[\\dA-Z]{4}\\d{21}",
        PL: "\\d{24}",
        PT: "\\d{21}",
        RO: "[A-Z]{4}[\\dA-Z]{16}",
        SM: "[A-Z]\\d{10}[\\dA-Z]{12}",
        SA: "\\d{2}[\\dA-Z]{18}",
        RS: "\\d{18}",
        SK: "\\d{20}",
        SI: "\\d{15}",
        ES: "\\d{20}",
        SE: "\\d{20}",
        CH: "\\d{5}[\\dA-Z]{12}",
        TN: "\\d{20}",
        TR: "\\d{5}[\\dA-Z]{17}",
        AE: "\\d{3}\\d{16}",
        GB: "[A-Z]{4}\\d{14}",
        VG: "[\\dA-Z]{4}\\d{16}",
      };

      bbanpattern = bbancountrypatterns[countrycode];

      // As new countries will start using IBAN in the
      // future, we only check if the countrycode is known.
      // This prevents false negatives, while almost all
      // false positives introduced by this, will be caught
      // by the checksum validation below anyway.
      // Strict checking should return FALSE for unknown
      // countries.
      if (typeof bbanpattern !== "undefined") {
        ibanregexp = new RegExp("^[A-Z]{2}\\d{2}" + bbanpattern + "$", "");
        if (!ibanregexp.test(iban)) {
          return false; // Invalid country specific format
        }
      }

      // Now check the checksum, first convert to digits
      ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4);
      for (i = 0; i < ibancheck.length; i++) {
        charAt = ibancheck.charAt(i);
        if (charAt !== "0") {
          leadingZeroes = false;
        }
        if (!leadingZeroes) {
          ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(
            charAt
          );
        }
      }

      // Calculate the result of: ibancheckdigits % 97
      for (p = 0; p < ibancheckdigits.length; p++) {
        cChar = ibancheckdigits.charAt(p);
        cOperator = "" + cRest + "" + cChar;
        cRest = cOperator % 97;
      }
      return cRest === 1;
    },
    "Bitte geben Sie eine gültige IBAN an."
  );

  var v = $("#registerform").validate({
    rules: {
      plz: {
        required: true,
        rangelength: [5, 5],
        digits: true,
      },
      ort: {
        required: true,
        letterswithbasicpunc: true,
      },
      hausnummer: {
        required: true,
        maxlength: 8
      },      
      iban: {
        required: true,
        iban: true,
      },
      firma: {
        required: "#firmenkunde:checked"
      },
      ustid: {
        required: function(element){
            
            steuernummerValLen = $("#steuernummer").val().length;
            finanzamtValLen = $("#finanzamt").val().length;
            bRequired = false;

            if (steuernummerValLen == 0 && finanzamtValLen == 0) {
                bRequired = true;
            }
            return bRequired;
        }
      },
      steuernummer: {
        required: function(element){
            
            ustidValLen = $("#ustid").val().length;
            steuernummerValLen = $("#steuernummer").val().length;
            finanzamtValLen = $("#finanzamt").val().length;
            bRequired = false;

            if (finanzamtValLen > 0 && ustidValLen == 0) {
                bRequired = true;
            }
            return bRequired;
        }
      },
      finanzamt: {
        required: function(element){
            
            ustidValLen = $("#ustid").val().length;
            steuernummerValLen = $("#steuernummer").val().length;
            finanzamtValLen = $("#finanzamt").val().length;
            bRequired = false;

            if (steuernummerValLen > 0 && ustidValLen == 0) {
                bRequired = true;
            }
            return bRequired;
        }
      }             
    },
    messages: {
      plz: {
        rangelength: "Die PLZ muss 5 Zeichen lang sein.",
        digits: "Es sind nur Zahlen erlaubt.",
      },
      hausnummer: {
        maxlength: "Es sind max. 8 Zeichen erlaubt."
      },      
    },
    errorClass: "warning",
  });

  $(".next").on("click", function () {
    if (v.form()) {
      currentStep++;
      current_fs = $(this).parent();
      next_fs = $(this).parent().next();

      //Add Class Active
      $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

      //show the next fieldset
      next_fs.show();
      //hide the current fieldset with style
      current_fs.animate(
        { opacity: 0 },
        {
          step: function (now) {
            // for making fielset appear animation
            opacity = 1 - now;

            current_fs.css({
              display: "none",
              position: "relative",
            });
            next_fs.css({ opacity: opacity });

            document.getElementById("registerSection_anchor").scrollIntoView();
          },
          duration: 600,
        }
      );
    }
  });

  $(".previous").on("click", function () {
    currentStep--;
    current_fs = $(this).parent();
    previous_fs = $(this).parent().prev();

    //Remove class active
    $("#progressbar li")
      .eq($("fieldset").index(current_fs))
      .removeClass("active");

    //show the previous fieldset
    previous_fs.show();

    //hide the current fieldset with style
    current_fs.animate(
      { opacity: 0 },
      {
        step: function (now) {
          // for making fielset appear animation
          opacity = 1 - now;

          current_fs.css({
            display: "none",
            position: "relative",
          });
          previous_fs.css({ opacity: opacity });

          document.getElementById("registerSection_anchor").scrollIntoView();
        },
        duration: 600,
      }
    );
  });

  //show more fields if option is selected
  $("#taxAddiotional").hide();
  $('input[type="radio"]').click(function(){
    var inputValue = $(this).attr("value");
    if ( inputValue === "vp_regel" && inputValue !== "vp_priv") {
      $("#taxAddiotional").show("slow");
    } else {
      $("#taxAddiotional").hide("slow");
    }
  });  

  $("#registerform").submit(function (e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.

    if (currentStep == anzSteps-1 && v.form()) {
      var form = $(this);
      var formData = new FormData(this);
      var actionUrl = form.attr("action");
    
      $.ajax({
        type: "POST",
        url: actionUrl,
        data: formData, // serializes the form's elements.
        success: function (data) {
          if (data.success == true) {
            showSuccess();
          }
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    }
  });

  function showSuccess() {
    $("#registerform").hide();
    $("#regSuccess").show();

    //jetzt konfettis regnen lassen!
    confetti("regSuccess", {
      angle: 90,
      count: 25,
      position: { x: 50, y: 50 },
      spread: 90,
      startVelocity: 60,
      decay: 0.9,
      gravity: 1,
      drift: 0,
      ticks: 200,
      colors: ["#fff", "#f00"],
      shapes: ["square", "circle"],
      scalar: 1,
      zIndex: 2000,
      disableForReducedMotion: true
    });


  }

  $("#regAgain").on("click", function () {
    currentStep = 0;

    $("#progressbar li").removeClass("active");
    $("#progressbar li").first().addClass("active");

    $("#registerform fieldset").css({
      display: "none",
      position: "relative",
      opacity: 1,
    });

    $("#kfzfront").val("");
    $("#kfzfront").parent().find("span.fname").html("KFZ-Schein Vorderseite");
    $("#kfzback").val("");
    $("#kfzback").parent().find("span.fname").html("KFZ-Schein Rückseite");
    $("#registerform fieldset").first().show();
    $("#registerform").show();
    $("#regSuccess").hide();
  });

  $(".inputfile").on("change", function () {
    var desc = $(this).parent().find("span.fname");

    if ($(this).attr("id") == "kfzfront") {
      desc.html("KFZ-Schein Vorderseite");
    } else {
      desc.html("KFZ-Schein Rückseite");
    }

    if (this.files.length) {
      var uploaditem = this.files.item(0);
      // check filesize
      if (uploaditem.size > 2490000) {
        desc.html(
          '<span style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Die Datei ist zu groß (max. 2,5 MB)</span>'
        );
        $(this).val("");
      } else if (
        uploaditem.type != "image/jpeg" &&
        uploaditem.type != "image/jpg" &&
        uploaditem.type != "image/png" &&
        uploaditem.type != "application/pdf"
      ) {
        desc.html(
          '<span style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ungültiger Dateityp (.jpg, .png, .pdf)</span>'
        );
        $(this).val("");
      } else {
        desc.html(this.files.item(0).name);
      }
    }
  });
});
